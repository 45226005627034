import React from "react"
import Hero from "../../../common/hero"

const BookingHero = () => {
  return (
    <Hero>
      <p> Här kan du boka</p>
    </Hero>
  )
}

export default BookingHero
