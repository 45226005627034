import React from "react"
import AvailableTimes from "../components/pages/bokning/available-times"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import BookingHero from "../components/pages/bokning/booking-hero"

const Bokning = () => {
  return (
    <Layout transparent currentSiteUrl="/bokning">
      <SEO title='Bokning' />
      <BookingHero />
      <AvailableTimes />
    </Layout>
  )
}
export default Bokning
